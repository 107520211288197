.spinner-container {
	display: flex;
	justify-content: center;
}

.spinner-container > svg {
	animation: 2s linear infinite svg-animation;
}

.spinner-container > svg > circle {
	stroke: #e711b8;
	stroke-width: 5;
	stroke-dasharray: 100px;
	stroke-dashoffset: 99px;
	stroke-linecap: round;
	transform-origin: 50% 50%;
	animation: 1.4s ease-in-out infinite both offset-animation;
}

@keyframes offset-animation {
	0%,
	25% {
		stroke-dashoffset: 97.5;
		transform: rotate(0);
	}

	50%,
	75% {
		stroke-dashoffset: 18.75;
		transform: rotate(45deg);
	}

	100% {
		stroke-dashoffset: 97.5;
		transform: rotate(360deg);
	}
}

@keyframes svg-animation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
