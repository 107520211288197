.viewOnlyBanner {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: #525151;
}

.viewOnlyBanner p {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 11px;
}

.viewOnlyBanner img {
  margin-left: 20px;
}
